import axios from "axios";

export default {
    state: {
        products: [],
        paginationOpts: {},
        brandOptions: [],
        productCategories: [],
        baseUnit: [],
        unitConversation: [],
        warehouses: [],
        hardness: [],
        sharpness: [],
        color: [],
        shape: [],
        nooffaces: [],
        clarity: [],
        treatment: [],
        effect: [],
        taxes: [],
    },
    getters: {
        products: (state) => state.products,
        paginationOpts: (state) => state.paginationOpts,
        brandOptions: (state) => state.brandOptions,
        productCategories: (state) => state.productCategories,
        baseUnit: (state) => state.baseUnit,
        warehouses: (state) => state.warehouses,
        hardness: (state) => state.hardness,
        sharpness: (state) => state.sharpness,
        color: (state) => state.color,
        shape: (state) => state.shape,
        nooffaces: (state) => state.nooffaces,
        clarity: (state) => state.clarity,
        treatment: (state) => state.treatment,
        effect: (state) => state.effect,
        taxes: (state) => state.taxes,
        unitConversation: (state) => state.unitConversation
    },
    mutations: {
        setProducts(state, payload) {
            state.products = payload
        },
        setProductPageOpts(state, payload) {
            state.paginationOpts = payload
        },
        setBrandOptions(state, payload) {
            state.brandOptions = payload
        },
        setProductCategories(state, payload) {
            state.productCategories = payload
        },
        setBaseUnit(state, payload) {
            state.baseUnit = payload
        },
        setWarehouses(state, payload) {
            state.warehouses = payload
        },
        setHardness(state, payload) {
            state.hardness = payload
        },
        setSharpness(state, payload) {
            state.sharpness = payload
        },
        setColor(state, payload) {
            state.color = payload
        },
        setShape(state, payload) {
            state.shape = payload
        },
        setNumberofFaces(state, payload) {
            state.nooffaces = payload
        },
        setClarity(state, payload) {
            state.clarity = payload
        },
        setTreatment(state, payload) {
            state.treatment = payload
        },
        setEffect(state, payload) {
            state.effect = payload
        },
        setTaxes(state, payload) {
            state.taxes = payload
        },
        setunitConversation(state, payload) {
            state.unitConversation = payload;
        }
    },
    actions: {
        getProduct({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Product/"+payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getProducts({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/Product?"+payload : "/Product"
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setProducts', response.data);
                        commit('setProductPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addProduct({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/Product", payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        // resolve('Successfully added')
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        updateProduct({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put("/product/"+payload.id, payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        deleteProduct({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/product/"+payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully deleted')
                    })
                    .catch((error) => {
                        const msg = (error.response) ? error.response.data[0] : error.message
                        reject(msg);
                    });
            })
        },
        getBrandList({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Brands")
                    .then((response) => {
                        commit('setBrandOptions', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getProductCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/ProductCategories?isDropDown=true")
                    .then((response) => {
                        commit('setProductCategories', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getBaseUnit({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/unitConversation/dropDown")
                    .then((response) => {
                        commit('setBaseUnit', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getunitConversation({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/unitConversation")
                    .then((response) => {
                        commit('setunitConversation', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getWarehouses({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Warehouses")
                    .then((response) => {
                        commit('setWarehouses', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getHardness({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient1")
                    .then((response) => {
                        commit('setHardness', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getSharpness({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient2")
                    .then((response) => {
                        commit('setSharpness', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getColor({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient3")
                    .then((response) => {
                        commit('setColor', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getShape({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient4")
                    .then((response) => {
                        commit('setShape', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getNumberofFaces({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient5")
                    .then((response) => {
                        commit('setNumberofFaces', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getClarity({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient6")
                    .then((response) => {
                        commit('setClarity', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getTreatment({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient7")
                    .then((response) => {
                        commit('setTreatment', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getEffect({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/varient8")
                    .then((response) => {
                        commit('setEffect', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getTaxes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/taxes")
                    .then((response) => {
                        commit('setTaxes', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addVarient({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(payload.url, payload.data)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        // resolve('Successfully added')
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
    },
}