import axios from "axios";

export default {
  state: {
    loggedInUser:
      localStorage.getItem("userInfo") != null
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null,
    loading: false,
    error: null,
  },
  getters: {
    loggedInUser: (state) => state.loggedInUser,
    loading: (state) => state.loading,
    error: (state) => state.error,
  },
  mutations: {
    setUser(state, data) {
      state.loggedInUser = data;
      state.loading = false;
      state.error = null;
    },
    setLogout(state) {
      state.loggedInUser = null;
      state.loading = false;
      state.error = null;
    },
    setLoading(state, data) {
      state.loading = data;
      state.error = null;
    },
    setError(state, data) {
      state.error = data;
      state.loggedInUser = null;
      state.loading = false;
    },
    clearError(state) {
      state.error = null;
    },
  },
  actions: {
    login({ commit }, payload) {
      commit("clearError");
      commit("setLoading", true);
      return new Promise((resolve, reject) => {
        axios
        .post("/Authentication", payload)
        .then((response) => {
          commit("setUser", response.data);
          localStorage.setItem("userInfo", JSON.stringify(response.data));
          localStorage.setItem("accesstoken", response.data.bearerToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.bearerToken}`;
          resolve();
        })
        .catch((error) => {
          localStorage.removeItem("accesstoken");
          localStorage.removeItem("userInfo", null);
          commit("setError", error);
          reject();
        });
      })
    },

    signUserUp({ commit }, data) {
      commit("setLoading", true);
      commit("clearError");
      
    },
    signOut({ commit }) {
      localStorage.removeItem("accesstoken");
      localStorage.removeItem("userInfo", null);
      commit("setLogout");
      // this.$router.go('/app/session/signin');
    },
  },
};
