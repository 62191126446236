import Vuex from "vuex";
import Vue from "vue";
import largeSidebar from "./modules/largeSidebar";
import compactSidebar from "./modules/compactSidebar";
import chat from "./modules/chat";
import config from "./modules/config";
import authData from "./modules/authData";
import invoice from "./modules/invoice";
import cart from "./modules/cart";
import verticalSidebar from "./modules/verticalSidebar";
import scrumboard from "./modules/scrumboard";
import products from "./modules/products";
import vendors from "./modules/vendors";
import purchaseOrders from "./modules/purchaseOrders";
import salesOrders from "./modules/salesOrder";
import common from "./modules/common";
import customers from "./modules/customers";
import accounts from "./modules/accounts";
import quotations from "./modules/quotations";
import inventory from "./modules/inventory";
import user from "./modules/user";
import reminder from "./modules/reminder";


// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    largeSidebar,
    compactSidebar,
    chat,
    config,
    authData,
    invoice,
    cart,
    verticalSidebar,
    scrumboard,
    products,
    vendors,
    purchaseOrders,
    salesOrders,
    common,
    customers,
    accounts,
    quotations,
    inventory,
    user,
    reminder
  }
});
