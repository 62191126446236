import axios from "axios";

export default {
    state: {
        salesOrders: [],
        salesPaginationOpts: {},
    },
    getters: {
        salesOrders: (state) => state.salesOrders,
        salesPaginationOpts: (state) => state.salesPaginationOpts,
    },
    mutations: {
        setSalesOrders(state, payload) {
            state.salesOrders = payload
        },
        setSalesPageOpts(state, payload) {
            state.salesPaginationOpts = payload
        },
    },
    actions: {
        getSalesOrders({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/salesOrder"+payload : "/salesOrder"
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setSalesOrders', response.data);
                        commit('setSalesPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addSalesOrder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/salesOrder", payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
    },
}