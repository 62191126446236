import axios from "axios";

export default {
    state: {
        countries: [],
        city: [],
        shippingCity: [],
        billingCity: [],
    },
    getters: {
        countries: (state) => state.countries,
        city: (state) => state.city,
        shippingCity: (state) => state.shippingCity,
        billingCity: (state) => state.billingCity,
    },
    mutations: {
        setCountries(state, payload) {
            state.countries = payload
        },
        setCity(state, payload) {
            state.city = payload
        },
        setShippingCity(state, payload) {
            state.shippingCity = payload
        },
        setSillingCity(state, payload) {
            state.billingCity = payload
        },
    },
    actions: {
        getCountries({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Countries")
                    .then((response) => {
                        commit('setCountries', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getCity({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/City/country?countryName=${payload}&&cityName=`)
                    .then((response) => {
                        let city = response.data.map(c => c.cityName);
                        commit('setCity', city);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getShippingCity({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/City/country?countryName=${payload}&&cityName=`)
                    .then((response) => {
                        let city = response.data.map(c => c.cityName);
                        commit('setShippingCity', city);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getBillingCity({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/City/country?countryName=${payload}&&cityName=`)
                    .then((response) => {
                        let city = response.data.map(c => c.cityName);
                        commit('setBillingCity', city);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
    },
}