import axios from "axios";

export default {
    state: {
        inventory: [],
        purchasePaginationOpts: {},
    },
    getters: {
        inventory: (state) => state.inventory,
        inventoryPaginationOpts: (state) => state.purchasePaginationOpts,
    },
    mutations: {
        setInventory(state, payload) {
            state.inventory = payload
        },
        setInventoryPageOpts(state, payload) {
            state.inventoryPaginationOpts = payload
        },
    },
    actions: {
        getInventory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/inventory"+payload : "/inventory"
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setInventory', response.data);
                        commit('setInventoryPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addInventory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/inventory", payload)
                    .then((response) => {
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
    },
}