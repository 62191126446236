// import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import VueRouter from "vue-router";
import GullKit from "./plugins/gull.kit";
// import "babel-polyfill";
// import es6Promise from "es6-promise";
// es6Promise.polyfill();
import store from "./store";
import Breadcumb from "./components/breadcumb";
import i18n from "./lang/lang";
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
//import material-icon scss
import "font-awesome/css/font-awesome.min.css";
import axios from './plugins/axios'

//defined as global component
Vue.component('VueFontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default);

Vue.component("breadcumb", Breadcumb);
import InstantSearch from 'vue-instantsearch';
// Vue.use(VueRouter);
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vuelidate from 'vuelidate';

Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)



Vue.use(InstantSearch);
Vue.use(GullKit);
Vue.use(axios);

import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    // your theme options
  },
});

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false;

if (process.env.NODE_ENV !== 'production') {
  Vue.config.devtools = true;
}

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
