import axios from "axios";

export default {
    state: {
        users: [],
        proofTypes: [],
        userPaginationOpts: {},
    },
    getters: {
        users: (state) => state.users,
        proofTypes: (state) => state.proofTypes,
        userPaginationOpts: (state) => state.userPaginationOpts
    },
    mutations: {
        setUsers(state, payload) {
            state.users = payload
        },
        setProofTypes(state, payload) {
            state.proofTypes = payload
        },
        setUsersPageOpts(state, payload) {
            state.userPaginationOpts = payload
        }
    },
    actions: {
        getUsersList({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/user/GetUsers?"+payload : "/user/GetUsers";
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setUsers', response.data);
                        commit('setUsersPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        deleteUserVendor({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/supplier/"+payload)
                    .then((response) => {
                        resolve('Successfully deleted')
                    })
                    .catch((error) => {
                        const msg = (error.response) ? error.response.data[0] : error.message
                        reject(msg);
                    });
            })
        },
    },
}