import axios from "axios";

export default {
    state: {
        quotations: [],
        quotationPaginationOpts: {},
    },
    getters: {
        quotations: (state) => state.quotations,
        quotationPaginationOpts: (state) => state.quotationPaginationOpts
    },
    mutations: {
        setQuotations(state, payload) {
            state.quotations = payload
        },
        setQuotationsPageOpts(state, payload) {
            state.quotationPaginationOpts = payload
        }
    },
    actions: {
        getQuotations({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/purchaseorder?"+payload : "/purchaseorder";
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setQuotations', response.data);
                        commit('setQuotationsPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addQuotation({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/Quotation", payload)
                    .then((response) => {
                        // commit('setQuotations', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        deleteQuotation({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/quotation/"+payload)
                    .then((response) => {
                        // commit('setQuotations', response.data);
                        resolve('Successfully deleted')
                    })
                    .catch((error) => {
                        const msg = (error.response) ? error.response.data[0] : error.message
                        reject(msg);
                    });
            })
        },
    },
}