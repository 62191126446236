import axios from "axios";

export default {
    state: {
        customers: [],
        customerPaginationOpts: {},
    },
    getters: {
        customers: (state) => state.customers,
        customerPaginationOpts: (state) => state.customerPaginationOpts
    },
    mutations: {
        setCustomers(state, payload) {
            state.customers = payload
        },
        setCustomersPageOpts(state, payload) {
            state.customerPaginationOpts = payload
        }
    },
    actions: {
        getCustomer({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/Customer/'+payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getCustomers({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/Customer?"+payload : "/Customer";
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setCustomers', response.data);
                        commit('setCustomersPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addCustomer({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/Customer", payload)
                    .then((response) => {
                        // commit('setCustomers', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        updateCustomer({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put("/customer/"+payload.id, payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        deleteCustomer({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/customer/"+payload)
                    .then((response) => {
                        // commit('setCustomers', response.data);
                        resolve('Successfully deleted')
                    })
                    .catch((error) => {
                        const msg = (error.response) ? error.response.data[0] : error.message
                        reject(msg);
                    });
            })
        },
    },
}