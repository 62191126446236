import axios from "axios";

export default {
    state: {
        accounts: [],
        accountsPaginationOpts: {},
        expensecategories: [],
        users: []
    },
    getters: {
        accounts: (state) => state.accounts,
        accountsPaginationOpts: (state) => state.accountsPaginationOpts,
        expensecategories: (state) => state.expensecategories,
        users: (state) => state.users,
    },
    mutations: {
        setAccounts(state, payload) {
            state.accounts = payload
        },
        setAccountsPageOpts(state, payload) {
            state.accountsPaginationOpts = payload
        },
        setExpenseCategories(state, payload) {
            state.expensecategories = payload
        },
        setUsers(state, payload) {
            state.users = payload
        },
    },
    actions: {
        getAccounts({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/expense"+payload : "/expense"
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setAccounts', response.data);
                        commit('setAccountsPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getExpenseCategory({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/ExpenseCategory/"+payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getExpenseCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/expensecategories?"+payload : "/expensecategories"
                axios
                    .get(url)
                    .then((response) => {
                        commit('setExpenseCategories', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getUsers({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/user/GetUsers"+payload : "/user/GetUsers"
                axios
                    .get(url)
                    .then((response) => {
                        commit('setUsers', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addAccounts({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/expense", payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        deleteAccounts({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/expense/"+payload)
                    .then((response) => {
                        resolve('Successfully deleted')
                    })
                    .catch((error) => {
                        const msg = (error.response) ? error.response.data[0] : error.message
                        reject(msg);
                    });
            })
        },
        addExpenseCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/expensecategory", payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        updateExpenseCategories({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put("/expensecategory/"+payload.id, payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
    },
}