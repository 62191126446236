import axios from "axios";

export default {
    state: {
        reminder: [],
        reminderPaginationOpts: {}
    },
    getters: {
        reminder: (state) => state.reminder,
        reminderPaginationOpts: (state) => state.reminderPaginationOpts,
    },
    mutations: {
        setReminder(state, payload) {
            state.reminder = payload
        },
        setRemindersPageOpts(state, payload) {
            state.reminderPaginationOpts = payload
        },
    },
    actions: {
        getReminder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Reminder/"+payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getReminders({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/Reminder/GetReminders?"+payload : "/Reminder/GetReminders"
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setRemindersPageOpts', respHeaders);
                        commit('setReminder', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addReminder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/Reminder", payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        updateReminder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put("/Reminder/"+payload.id, payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
    },
}