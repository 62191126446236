import axios from "axios";

export default {
    state: {
        orders: [],
        orderNo: "",
        purchasePaginationOpts: {},
        currency: [],
    },
    getters: {
        orders: (state) => state.orders,
        orderNo: (state) => state.orderNo,
        purchasePaginationOpts: (state) => state.purchasePaginationOpts,
        currency: (state) => state.currency,
    },
    mutations: {
        setOrders(state, payload) {
            state.orders = payload
        },
        setOrderNumber(state, payload) {
            state.orderNo = payload
        },
        setPurchasePageOpts(state, payload) {
            state.purchasePaginationOpts = payload
        },
        setCurrency(state, payload) {
            state.currency = payload
        }
    },
    actions: {
        getOrders({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/PurchaseOrder"+payload : "/PurchaseOrder"
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setOrders', response.data);
                        commit('setPurchasePageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addPurchaseOrder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/PurchaseOrder", payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        deletePurchaseOrder({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/PurchaseOrder/"+payload)
                    .then((response) => {
                        resolve('Successfully deleted')
                    })
                    .catch((error) => {
                        const msg = (error.response) ? error.response.data[0] : error.message
                        reject(msg);
                    });
            })
        },
        getPurchaseOrderNumber({ commit }, payload) {
            return new Promise((resolve, reject) => {
                const param = payload ? payload.param : true;

                axios
                    .get(`/purchaseorder/newOrderNumber/${param}`)
                    .then((response) => {
                        commit('setOrderNumber', response.data.orderNumber)
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getCurrency({ commit }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Currency")
                    .then((response) => {
                        commit('setCurrency', response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addPurchaseOrderPayment({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/PurchaseOrderPayment", payload)
                    .then((response) => {
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
    },
}