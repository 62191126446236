import axios from "axios";

export default {
    state: {
        vendors: [],
        proofTypes: [],
        vendorPaginationOpts: {},
    },
    getters: {
        vendors: (state) => state.vendors,
        proofTypes: (state) => state.proofTypes,
        vendorPaginationOpts: (state) => state.vendorPaginationOpts
    },
    mutations: {
        setVendors(state, payload) {
            state.vendors = payload
        },
        setProofTypes(state, payload) {
            state.proofTypes = payload
        },
        setVendorsPageOpts(state, payload) {
            state.vendorPaginationOpts = payload
        }
    },
    actions: {
        getVendor({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/Supplier/"+payload)
                    .then((response) => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        getVendors({ commit }, payload) {
            return new Promise((resolve, reject) => {
                let url = payload ? "/Supplier?"+payload : "/Supplier";
                axios
                    .get(url)
                    .then((response) => {
                        let respHeaders = JSON.parse(response.headers['x-pagination']);
                        commit('setVendors', response.data);
                        commit('setVendorsPageOpts', respHeaders);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
        addVendor({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/Supplier", payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully added')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        updateVendor({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put("/Supplier"+payload.id, payload)
                    .then((response) => {
                        resolve('Successfully Updated')
                    })
                    .catch((error) => {
                        reject(error.message);
                    });
            })
        },
        deleteVendor({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/supplier/"+payload)
                    .then((response) => {
                        // commit('setVendors', response.data);
                        resolve('Successfully deleted')
                    })
                    .catch((error) => {
                        const msg = (error.response) ? error.response.data[0] : error.message
                        reject(msg);
                    });
            })
        },
        getProofTypes({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/ProofTypes")
                    .then((response) => {
                        console.log(response)
                        commit('setProofTypes', response.data);
                        resolve()
                    })
                    .catch((error) => {
                        console.log('Error');
                        reject('Error');
                    });
            })
        },
    },
}