import axios from 'axios';
import constants from "@/constants";

const instance = axios.create({
    baseURL: constants.apiBaseUrl
});

axios.defaults.baseURL = constants.apiBaseUrl

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accesstoken')}`;

export default {
    install: function (Vue) {
        Object.defineProperty(Vue.prototype, '$axios', { value: instance });
    }
}